// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledUnverified = styled.small`
	vertical-align: middle;
	display: inline-block;
	color: #c1c2c3;
	font-weight: bold;
	font-size: 0.75rem;
	text-transform: uppercase;
`;

// #####################################################
